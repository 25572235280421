import React, { useState } from 'react';

const Chat = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    //Do a get request to get the messages from the server
    const getConfidence = (newMessage) => {
        //Make a request to the server to get the messages
        return fetch('https://k2c-test.epiphyte.ai/?text=' + newMessage)
            .then((response) => {
                return response.text()
            })
            .then((data) => {
                console.log(data);
                return data;
            });
    };


    const handleSendMessage = () => {
        if (newMessage.trim() !== '') {
            setMessages([...messages, newMessage]);
            console.log(newMessage);
            getConfidence(newMessage).then((data) => {
                setNewMessage('');
                setMessages([...messages, newMessage, data])
            });
        }
    };

    const handleInputChange = (event) => {
        setNewMessage(event.target.value);
    };

    return (
        <div>
            <div>
                {messages.map((message, index) => (
                    <p key={index}>{message}</p>
                ))}
            </div>
            <input
                type="text"
                value={newMessage}
                onChange={handleInputChange}
            />
            <button onClick={handleSendMessage}>Send</button>
        </div>
    );
};

export default Chat;
